document.querySelectorAll('textarea').forEach(function(el) {
    el.style.overflow = 'hidden';
    el.style.resize = 'none';
    el.style.minHeight = ((el.rows ? el.rows : 2) * 30) + 'px';
    el.oninput = function() {
        el.style.height = "5px";
        el.style.height = (el.scrollHeight)+"px";
    }
    el.oninput();
})
