const errorMessages = JSON.parse(document.getElementById('error-messages').innerHTML);
document.querySelectorAll(".validate").forEach(function(field) {
    field.oninvalid = function() {
        if (field.validity.patternMismatch && field.classList.contains('confirm-field')) {
            field.setCustomValidity(errorMessages.confirm);
        }
        else if(field.validity.valueMissing && field.required) {
            field.setCustomValidity(errorMessages.required);
        }
        else if(field.validity.typeMismatch && field.classList.contains('v-email')) {
            field.setCustomValidity(errorMessages.email);
        }
        else if(field.validity.patternMismatch && field.classList.contains('v-password')) {
            field.setCustomValidity(errorMessages.password);
        }
        else if(field.validity.patternMismatch && field.classList.contains('v-phone')) {
            field.setCustomValidity(errorMessages.phone);
        }
        else {
            field.setCustomValidity('');
        }
    };
    field.onblur = function() {
        if(!field.checkValidity()) {
            field.classList.add('is-invalid');
        } else {
            field.classList.remove('is-invalid');
        }
    };
    if(field.classList.contains('v-confirm')) {
        let confirmation = document.getElementById(field.id + '_confirmation');
        field.onkeyup = function() {
            confirmation.pattern = field.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };
    }
    if(field.classList.contains('confirm-field')) {
        field.onkeyup = function() {
            field.checkValidity();
        };
    }
});
