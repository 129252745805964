import flatpickr from "flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { French } from "flatpickr/dist/l10n/fr.js";

flatpickr.l10ns.default.firstDayOfWeek = 1;
let html = document.querySelector('html');
if(html.lang === 'nl') {
    flatpickr.localize(Dutch);
}
else if(html.lang === 'de') {
    flatpickr.localize(German);
}
else if(html.lang === 'fr') {
    flatpickr.localize(French);
}
flatpickr.rangePlugin = require("flatpickr/dist/plugins/rangePlugin.js");

function initDatePicker(el) {
    if(el._flatpickr === undefined) {
        el.flatpickr(getDateOptions(el))
        if(el.dataset.before) {
            let before = initDatePicker(document.getElementById(el.dataset.before));
            before.config.onChange.push(function(selectedDates, dateStr, instance) {
                el._flatpickr.config.maxDate = selectedDates[0].fp_incr(el.dataset.offset ?? -1);
            });
        }
        if(el.dataset.after) {
            let after = initDatePicker(document.getElementById(el.dataset.after));
            after.config.onChange.push(function(selectedDates, dateStr, instance) {
                el._flatpickr.config.minDate = selectedDates[0].fp_incr(el.dataset.offset ?? 1);
            });
        }
    }
    return el._flatpickr;
}

function initTimePicker(el) {
    if(el._flatpickr === undefined) {
        el.flatpickr(getTimeOptions(el));
    }
    return el._flatpickr;
}

function getTimeOptions(el) {
    return {
        onChange: [],
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        allowInput: !el.classList.contains('readonly')
    };
}

function getDateOptions(el) {
    let options = {
        onChange: [],
        dateFormat: 'Y-m-d',
        allowInput: !el.classList.contains('readonly')
    }
    if(el.classList.contains('past')) {
        if(el.classList.contains('today')) {
            options['maxDate'] = 'today';
        } else {
            options['maxDate'] = new Date().fp_incr(-1);
        }
    }
    else if(el.dataset.before) {
        options['maxDate'] = document.getElementById(el.dataset.before).value;
    }
    if(el.classList.contains('future')) {
        if(el.classList.contains('today')) {
            options['minDate'] = 'today';
        } else {
            options['minDate'] = new Date().fp_incr(1);
        }
    }
    else if(el.dataset.after) {
        options['minDate'] = document.getElementById(el.dataset.after).value;
    }
    if(el.classList.contains('time')) {
        options['enableTime'] = true;
        options['time_24hr'] = true;
        options['dateFormat'] += ' H:i';
    } else {
        options['enableTime'] = false;
    }
    return options;
}
document.querySelectorAll('.timepicker').forEach(initTimePicker);
document.querySelectorAll('.datepicker').forEach(initDatePicker);
