import 'bootstrap/js/src/tab';
// Change hash for page-reload
document.querySelectorAll('.nav-tabs .nav-link').forEach(function(el) {
    el.addEventListener('click', function (e) {
        window.history.replaceState({}, document.title, window.location.href.split('#')[0] + el.dataset.bsTarget)
    });
});
// Load tab from URL
if(window.location.hash) {
    const selectedTab = document.querySelector('.nav-tabs .nav-link[data-bs-target="' + window.location.hash + '"]')
    if (selectedTab) {
        selectedTab.click();
    }
}
